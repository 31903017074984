$spacer: 1rem;
$spacers: ();
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge( (0: 0, 1: $spacer / 4, 2: $spacer / 2, 3: $spacer, 4: $spacer * 1.5, 5: $spacer * 3, 6: $spacer * 3.5, 7: $spacer * 4, 8: $spacer * 4.5, 10: $spacer * 10, 15: $spacer * 15), $spacers);
// Configuration
@import "~bootstrap/scss/_functions";
@import "~bootstrap/scss/_variables";
@import "~bootstrap/scss/_mixins";
@import "~bootstrap/scss/_utilities";
// Layout & components
@import "~bootstrap/scss/_root";
@import "~bootstrap/scss/_reboot";
@import "~bootstrap/scss/_containers";
@import "~bootstrap/scss/_dropdown";
@import "~bootstrap/scss/_grid";
@import "~bootstrap/scss/_nav";
@import "~bootstrap/scss/_navbar";
@import "~bootstrap/scss/_images";
@import "~bootstrap/scss/_transitions";
//utilities
@import "~bootstrap/scss/utilities/_api";
$primaryColor: #b44114;
$secondaryColor: #B24012;
$subtitleColor: #343a40;
$textColor: #7e7e7e;
$sectionGutter: 50px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1400px;
$heroxxl: 1600px;
$text: 1rem;
$textsm: 1.125rem;
$textmd: 1.25rem;
$subHead: 1.125rem;
$subHeadsm: 1.25rem;
$subHeadmd: 1.75rem;
$head: 1.5rem;
$headsm: 2rem;
$headmd: 2.4rem;
$button: 0.81rem;
$buttonsm: 1rem;
body {
    font-family: 'Barlow Semi Condensed', sans-serif;
    min-width: 320px;
    scroll-behavior: smooth;
    padding-top: 140px;
    display: grid;
    min-height: calc(100vh + 1px);
}

button {
    font-weight: 900;
    text-transform: uppercase;
    font-size: $button;
    @media(min-width: $sm) {
        font-size: $buttonsm;
        width: 100%;
    }
    padding: 14px 27px;
    max-width: 186px;
}

h2 {
    font-size: $head;
    line-height: 1.2;
    @media(min-width: $sm) {
        font-size: $headsm;
    }
    @media(min-width: $md) {
        font-size: $headmd;
    }
    font-weight: bold;
    color: $primaryColor;
}

h3 {
    color: $subtitleColor;
    font-size: $subHead;
    @media(min-width: $sm) {
        font-size: $subHeadsm;
    }
    @media(min-width: $md) {
        font-size: $subHeadmd;
    }
    font-weight: 500;
    line-height: 1.4;
    margin-bottom: .5rem;
}

p,
li {
    font-size: $text;
    line-height: 1.7;
    @media(min-width: $sm) {
        font-size: $textsm;
    }
    @media(min-width: $md) {
        font-size: $textmd;
    }
}

.nav-link {
    font-weight: bold;
    text-transform: uppercase;
    color: $subtitleColor !important;
    &:hover {
        color: $primaryColor !important;
        text-decoration: underline;
    }
}

a {
    color: $primaryColor !important;
}

.back-to-top {
    position: fixed;
    display: none;
    right: 15px;
    bottom: 15px;
    z-index: 99999;
    cursor: pointer;
    p {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        width: 40px;
        height: 40px;
        border-radius: 4px;
        background: #b44114;
        color: #fff;
        transition: all 0.4s;
    }
    &.show {
        display: inline-block;
    }
}

.ri-arrow-up-line:before {
    content: "\21E7";
    text-decoration: none;
}

.navbar {
    background-color: #fff;
    border-bottom: 1px solid #70707050;
    z-index: 999999;
}

.fixed {
    position: fixed;
    top: 0;
    width: 100%;
}

.has-shadow {
    box-shadow: 0px -10px 13px 4px $subtitleColor;
}

.navbar-toggler,
.navbar-toggler-icon {
    border: none;
    transition: background-image .3s ease-in-out;
}

.collapsing {
    transition: none !important;
}

.navbar-toggler {
    padding: 5px;
    max-width: 40px;
    &:not([aria-expanded=false]) {
        .navbar-toggler-icon {
            background-image: url('./assets/close.svg');
            transition: background-image .3s ease-in-out;
            max-width: 30px;
        }
    }
}

strong {
    color: black;
}

.primary {
    color: $primaryColor;
}

.secondary {
    color: $secondaryColor;
}

.top-section {
    min-height: calc(100vh - 200px);
}

.section {
    margin-top: -150px;
    padding-top: 200px;
    @media(min-width: $lg) {
        margin-top: $sectionGutter;
        padding-top: 15px;
    }
    overflow: hidden;
}

.link {
    color: $primaryColor;
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.8;
    @media(min-width: $sm) {
        font-size: 1.25rem;
    }
}

a,
a:hover,
a:visited {
    cursor: pointer;
    color: $primaryColor;
}

a {
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
    &:visited {
        text-decoration: none;
    }
}

a:not([href]):not([class]) {
    color: $primaryColor;
    &:hover {
        text-decoration: underline;
    }
}

.welcome-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 50px;
    grid-auto-flow: row;
    text-align: center;
    justify-content: center;
}

.companies {
    display: grid;
    grid-auto-columns: 247px;
    grid-auto-flow: row;
    justify-content: center;
    grid-row-gap: 40px;
    @media(min-width: $sm) {
        grid-auto-flow: column;
        justify-content: space-between;
    }
}

.footer-links {
    cursor: pointer;
    color: $primaryColor;
    font-size: 0.9rem;
    display: grid;
    grid-auto-flow: row;
    @media(min-width: $sm) {
        grid-auto-flow: column;
        grid-column-gap: 34px;
    }
}

footer {
    align-self: end;
    margin-top: 30px;
    box-shadow: 0px 10px 13px 4px $subtitleColor;
    padding: 30px 15px;
    &>div {
        display: grid;
        justify-content: center;
        text-align: center;
        grid-auto-flow: row;
        @media(min-width: $sm) {
            justify-content: space-between;
            grid-auto-flow: column;
            text-align: left;
        }
    }
}

.primary-title {
    color: $primaryColor;
    font-weight: bold;
}

#impressum {
    line-height: 1.5;
    font-size: 1.25rem;
    h2 {
        margin-bottom: 40px;
    }
}

#datenschutz {
    ul,
    ol {
        padding-left: 1.1rem;
    }
    ol:not(.step)>li {
        margin-top: 2rem;
        &::marker {
            font-weight: bold;
            color: black;
        }
    }
    strong {
        margin-left: 2rem;
    }
    span {
        display: block;
    }
    .align-start {
        margin-left: -1rem;
    }
    .step {
        list-style-type: lower-roman;
    }
}